import React, { useState } from "react";
import useGetImages from "./useGetImages";
import ModalWindow from "../modalWindow";
import cardData from "./cardData";
import s from "./Cards.module.scss";
const Card = ({ title, imageUrl, handleOpenModal, id, amarilloIcon, }) => {
    const handleClick = e => {
        handleOpenModal(e.target.getAttribute("data-id"));
    };
    return (<div className={s.card}>
      <img className={s.bg} src={imageUrl.childImageSharp.fluid.src} alt={title}/>
      <div className={s.titleContainer}>
        <img className={s.bgIcon} src={amarilloIcon.childImageSharp.fluid.src} alt={title}/>
        <h3 className={s.title}>{title}</h3>
      </div>
    </div>);
};
const Cards = () => {
    const { fdaImage, globalGapImage, certifiedOrganicImage, salmonSafeImage, fdaIcon, globalGapIcon, certifiedOrganicIcon, salmonSafeIcon, globalGrowerImage, hopTechnicImage, amarilloIcon, } = useGetImages();
    const [modalContentInx, setModalContentInx] = useState(0);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const handleOpenModal = index => {
        setModalIsOpen(true);
        setModalContentInx(index);
    };
    const data = [
        {
            title: "Salmon-Safe",
            imageUrl: salmonSafeImage,
            modalContent: cardData[3],
            modalIcon: salmonSafeIcon,
        },
        {
            title: "Hop Research and Product Innovation",
            imageUrl: fdaImage,
            modalContent: cardData[0],
            modalIcon: fdaIcon,
        },
        {
            title: "Global Grower Program",
            imageUrl: globalGrowerImage,
            modalContent: cardData[2],
            modalIcon: certifiedOrganicIcon,
        },
        {
            title: "HopTechnicⓇ Quality Assurance Program",
            imageUrl: hopTechnicImage,
            modalContent: cardData[2],
            modalIcon: certifiedOrganicIcon,
        },
        {
            title: "VGF Worldwide Distribution",
            imageUrl: certifiedOrganicImage,
            modalContent: cardData[2],
            modalIcon: certifiedOrganicIcon,
        },
        {
            title: "Global G.A.P. Certification",
            imageUrl: globalGapImage,
            modalContent: cardData[1],
            modalIcon: globalGapIcon,
        },
    ];
    return (<div style={{ paddingTop: "25px" }}>
      <h1 style={{ marginBottom: "25px" }}>Core Processes</h1>
      <div className={s.box}>
        {data.map(({ title, imageUrl }, i) => (<Card key={title} title={title} imageUrl={imageUrl} handleOpenModal={handleOpenModal} id={i} amarilloIcon={amarilloIcon}/>))}
        <ModalWindow open={modalIsOpen} title={data[modalContentInx].title} modalContent={data[modalContentInx].modalContent} modalIcon={data[modalContentInx].modalIcon} closeModal={setModalIsOpen}/>
      </div>
      </div>);
};
export default Cards;
