import React from "react";
import BestPractices from "../scenes/bestPractices";
import SEO from "../components/seo";
import { keywords } from "../content/keywords";
export default () => {
    return (<>
      <SEO title={'Best Practices'} keywords={keywords}/>
      <BestPractices />
    </>);
};
