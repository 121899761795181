import React from "react";
const FoodSafetyContent = () => {
    return (<div>
      Virgil Gamache Farms, Inc. is{" "}
      <a href="https://www.fda.gov/food/guidanceregulation/fsma/" target="_blank" rel="noopener noreferrer">
        FSMA Certified
      </a>
      . VGF is one of a handful in the industry that is fully FSMA compliant.
      FSMA certification means we meet the revised, tougher best-farming and
      manufacturing standards Congress created in 2011. These practices enhance
      hop quality, product safety, and allow for complete traceability of
      products. Traceability is vitally important. In case of an industry safety
      event or a product recall, traceability provides vital information
      regarding when, where and how the products were produced.
      <br />
      <br />
      <a href="https://www.fda.gov/food/guidanceregulation/fsma/" target="_blank" rel="noopener noreferrer">
        FSMA
      </a>{" "}
      is transforming the nation’s food safety system! This is happening as we
      shift the focus from responding to food-borne illness to preventing them.
      VGF is doing its part by utilizing advanced production and data management
      systems. This happens in the organic farming stage, and continues through
      harvest, processing and distribution. Thus, we can track every lot of
      hops, down to the individual bale. These management practices assure the
      highest level of product quality and safety to the consumer. They also
      provide accountability to our distributors and brewers.
    </div>);
};
const GAPContent = () => {
    return (<div>
      In addition to FSMA certification, Virgil Gamache has obtained Global
      G.A.P. certification through our implementation of sustainable farming
      programs that are impacting agriculture worldwide. Specifically for hop
      growers, the program offers standards covering propagation material, soil
      management, pre-harvest evaluation, harvest and post-harvest activities.
      What’s more, participating farmers take steps to improve the safety,
      health and welfare of their workers, as well as to conserve natural
      resources. The Global G.A.P program at VGF is also one more level of
      product safety protection for our brewers. Learn more about{" "}
      <a href="https://www.globalgap.org/uk_en/" target="_blank" rel="noopener noreferrer">
        Global G.A.P.
      </a>
      here.
    </div>);
};
const CertifiedOrganicContent = () => {
    return (<div>
      Besides Global G.A.P certification, Virgil Gamache Farms, Inc.
      incorporates organic farming methods and is Certified Organic by the
      Washington State Department of Agriculture. To earn our organic hop
      farming certification we work diligently to recycle resources, promote
      ecological balance and conserve biodiversity. In addition, our organic hop
      farming cerfitifacation prohibits use of harmful inputs, such as sewage
      sludge, irradiation, genetic engineering and most synthetic fertilizers or
      pesticides.
    </div>);
};
const SalmonContent = () => {
    return (<div>
      Finally, protection of our rivers, streams and ground water is a top
      priority for the Gamache family. For that reason, VGF farms works
      diligently to meet all the Salmon-Safe Certification requirements. This
      means that VGF is:
      <br />
      <br />
      Controlling erosion by planting soil-protecting cover crops
      <br />
      <br />
      Protecting the watershed to assure healthy salmon, rivers and streams
      <br />
      <br />
      Implementing Integrated Pest Management utilizing organic, natural pest
      control
      <br />
      <br />
      Using high-efficiency drip irrigation
      <br />
      <br />
      Preventing harmful chemicals from entering streams and aquifers
      <br />
      <br />
      Promoting plant and wildlife diversity in the region
      <br />
      You can learn more about Virgil Gamache farms family legacy in the{" "}
      <a href="http://www.vgfinc.com/farm-history" target="_blank" rel="noopener noreferrer">
        Yakima Valley here.
      </a>
    </div>);
};
const cardData = [
    FoodSafetyContent,
    GAPContent,
    CertifiedOrganicContent,
    SalmonContent,
];
export default cardData;
