import { useStaticQuery, graphql } from "gatsby";
export default () => {
    const data = useStaticQuery(graphql `
    query {
      fdaImage: file(
        relativePath: { eq: "images/best_practices/card_images/2.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      globalGapImage: file(
        relativePath: { eq: "images/best_practices/card_images/6.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      globalGrowerImage: file(
        relativePath: { eq: "images/best_practices/card_images/3.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hopTechnicImage: file(
        relativePath: { eq: "images/best_practices/card_images/4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      certifiedOrganicImage: file(
        relativePath: { eq: "images/best_practices/card_images/5.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salmonSafeImage: file(
        relativePath: { eq: "images/best_practices/card_images/1.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hoptechnicImage: file(
        relativePath: { eq: "images/best_practices/card_images/4.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      fdaIcon: file(
        relativePath: { eq: "images/best_practices/popup_images/1.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      globalGapIcon: file(
        relativePath: { eq: "images/best_practices/popup_images/2.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      amarilloIcon: file(
        relativePath: { eq: "images/best_practices/popup_images/AmarilloIcon.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      certifiedOrganicIcon: file(
        relativePath: { eq: "images/best_practices/popup_images/3.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      salmonSafeIcon: file(
        relativePath: { eq: "images/best_practices/popup_images/4.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
    return data;
};
